import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="main-container">
      <div className="error-container">
        <div className="error-code">Oops!</div>
        <div className="error-text">
          The page you were looking for doesn't exist.
        </div>
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="info"
          size="large"
        >
          Go to Home
        </Button>
      </div>
    </div>
  );
};
